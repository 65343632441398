import * as React from "react"
import Form from "../components/form"

const Contact = ({phone, email, subtitle}) => (
  <div id='contact' className='contact p40 flex m-wrap'>
    <div className='w-40 m-100'>
      <p className='h2 m0 fade--in' data-sal>Get In Touch</p>
      <p className='medium grey m0 mt20 max-350 lh2 fade--in' data-sal>{subtitle}</p>
      <div className='mt40 fade--in' data-sal>
        <div className='flex align-center'>
          <span className='icon phone'/><a className='ml20 link inverse dark medium ul-link' href={'tel:'+phone}>{phone}</a>
        </div>
        <div className='flex align-center mt10'>
          <span className='icon email'/><a className='ml20 link inverse dark medium ul-link' href={'mailto:'+email}>{email}</a>
        </div>
        <div className='mt20 medium'>
          QBCC License: 15369325 
        </div>
        <div className='mt40 pt20 m-p0 fade--in' data-sal>
          <div className='verified-icon m-op-50'/>
        </div>
      </div>
    </div>
    <div className='w-60 m-100 m-mt40 m-pt40'>
      <p className='h2 m0 mb20 m-show'>Contact Us Below:</p>
      <Form />
    </div>
  </div>  
)

export default Contact
